


/* Estilos para pantallas móviles div 1 */
.home-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  color: #283A7F;
  border-radius: 20px;
  margin: 10px 0px 30px 10px;    
  padding: 20px;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); */
  text-align: center;
}

.home-container h1 {
  font-size: 40px;
  color: #f49420;
  font-family: 'LibreBaskerville';
}
.home-container p {
  font-size: 16px;
  width: 100%; 
  margin-left: 10px;
  color: #000;
  text-align: left;
  font-family: 'LibreBaskerville';
}
.home-container img {
  width: 100%;
  border-radius: 20px;
}

/* Estilos para pantallas grandes div 1 */
@media (min-width: 1000px) {
  .home-container {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 20px 50px;
  }
  .home-container .content-left, .home-container .content-right {
    flex: 1;
  }
  .home-container h1 {
    font-size: 42px;
    color: #f49420;
  }
  .home-container hr {
    width: 600px;
  }
  .home-container p {
    font-size: 18px;
    width: 70%; 
    margin-left: 60px;
    color: #000;
    text-align: left;
  }
  .home-container img {
    width: 100%;
    margin-right: 20px;
    border-radius: 20px;
  }
}

/* Estilos para pantallas móviles div 2 */
.home-container2 {
  display: flex;
  flex-direction: column;
  background-color: white;
  color: #283A7F;
  border-radius: 20px;
  margin: 10px 0px 30px 10px;    
  padding: 20px;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); */
  text-align: center;
}
.home-container2 img {
  width: 100%;
  margin: 0;
  border-radius: 20px;
}

.home-container2 h3 {
  font-size: 30px;
  width: 90%; 
  font-family: 'LibreBaskerville';
  margin: 30px;
  color: #f49420;
  text-align: left;
}

.home-container2 p {
  font-size: 18px;
  width: 100%; 
  margin-left: 10px;
  margin-top: 30px;
  color: #000;
  text-align: left;
  font-family: 'LibreBaskerville';
}
.home-container2 a {
  display: block;
  background-color: #283A7F;
  color: white;
  font-size: 1.2em;
  font-family: 'LEMON MILK Light';
  padding: 15px 10px;
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  width: auto; 
  max-width: 180px; 
  margin: auto;
}
.home-container2 a:hover {
  background-color: #f49420;
}

/* Estilos para pantallas grandes div 2*/
@media (min-width: 1000px) {
  .home-container2 {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 30px 50px;
  }
  .home-container2 img {
    width: 35%;
    margin-right: 20px;
    margin-left: 30px;
    border-radius: 20px;
  }
  .home-container2 div {
    flex: 1; 
  }
  .home-container2 h3 {
    font-size: 34px;
    width: 80%; 
    font-family: 'LibreBaskerville';
    margin-left: 80px;
    color: #f49420;
    text-align: left;
  }

  .home-container2 p {
    font-size: 18px;
    width: 70%; 
    margin-left: 80px;
    color: #000;
    text-align: left;
  }
  .home-container2 a {
    display: block;
    margin-top: 80px;
    margin-left: 280px;
    background-color: #283A7F;
    font-size: 1.2em;
    font-family: 'LEMON MILK Light';
    color: white;
    padding: 15px 20px;
    border-radius: 50px;
    text-align: center;
    text-decoration: none;
    width: auto; 
    max-width: 250px; 
}
}

/* Estilos para pantallas móviles div 3 */
.home-container3 {
  display: flex;
  flex-direction: column;
  background-color: white;
  color: #283A7F;
  border-radius: 20px;
  margin: 10px 0px 30px 10px;    
  padding: 20px;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); */
  text-align: center;
}
.home-container3 img {
  width: 100%;
  margin: 0;
  margin-top: 30px;
  border-radius: 20px;
}
.home-container3 h3 {
  font-size: 32px;
  width: 100%; 
  margin-left: 30px;
  margin-top: 30px;
  color: #000;
  text-align: left;
  font-family: 'LibreBaskerville';
}

.home-container3 h4 {
  font-size: 16px;
  width: 100%; 
  margin-left: 40px;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #f49420;
  text-align: left;
  font-family: 'LEMON MILK Light Italic';
}

.home-container3 a {
  display: block;
  background-color: #283A7F;
  color: white;
  font-size: 1.2em;
  font-family: 'LEMON MILK Light';
  padding: 15px 10px;
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  width: auto; 
  max-width: 180px; 
  margin: auto;
}

.home-container3 a:hover {
  background-color: #f49420;
}

/* Estilos para pantallas grandes div 3 */
@media (min-width: 1000px) {
  .home-container3 {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 30px 50px;
  }
  .home-container3 img {
    width: 45%;
    margin-right: 20px;
    margin-left: 30px;
    border-radius: 20px;
  }
  .home-container3 div {
    flex: 1; 
  }
  .home-container3 h3 {
    font-size: 32px;
    width: 50%; 
    margin-left: 80px;
    color: #000;
    text-align: left;
  }

  .home-container3 h4 {
    font-size: 20px;
    width: 100%; 
    margin-left: 80px;
    margin-top: 30px;
    margin-bottom: 30px;
    color: #f49420;
    text-align: left;
    font-family: 'LEMON MILK Light Italic';
  }

  .home-container3 a {
    display: block;
    margin-top: 50px;
    margin-left: 180px;
    background-color: #283A7F;
    font-size: 1.2em;
    font-family: 'LEMON MILK Light';
    color: white;
    padding: 15px 20px;
    border-radius: 50px;
    text-align: center;
    text-decoration: none;
    width: auto; 
    max-width: 250px; 
}
}




/* Animación para títulos al cargar */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Aplicación de la animación a los títulos */
.home-container h1,
.home-container2 h3,
.home-container3 h3 {
  opacity: 0; /* Comienza con opacidad 0 para la animación */
  animation: fadeIn 0.6s ease-in-out forwards; /* Aplica la animación */
}

/* Animación para imágenes al cargar */
@keyframes scaleIn {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

/* Aplicación de la animación a las imágenes */
.home-container img,
.home-container2 img,
.home-container3 img {
  transform: scale(0.8); /* Inicia con una escala menor */
  animation: scaleIn 0.6s ease-in-out forwards; /* Aplica la animación */
}
