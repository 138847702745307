.products-container {
    background-color: white;
    color: #000;
    border-radius: 20px;
    
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 30px;
    margin-bottom: 40px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column; 
     align-items: center;
    flex-wrap: wrap; 

  }

  .layout {
    width: 100%; 
    max-width: 1366px; 
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); 
    gap: 8px;
  }

  .visible {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 0.5s, transform 0.5s;
  }
  
  .quartz-title {
    margin: 20px;
    font-family: 'LEMON MILK Regular';
    color: #f49420;
    font-size: 40px;
    text-align: center !important;
  }
  
  .sizes{
    font-family: 'LEMON MILK Light';
    margin-bottom: 20px;
    
  }


.coverlam {
  margin-bottom: 50px;
}
  
  .raya {
    border: none;
    border-top: 1px solid #000;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .hr-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: small;
    font-family: 'LEMON MILK Light Italic';
    width: 100%;
    margin: 10px; 
    transition: transform 0.3s; 
}


.image-container:hover {
  transform: scale(1.05); 
}
  .image-wrapper {
    width: 200px; 
    height: 200px; 
    overflow: hidden;
    border-radius: 20px;
  }
  
  .image-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  
  .image-container img {
    width: 100%; 
    height: 100%; 
    object-fit: cover; 
  }
  

.image-fullscreen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 9999;
  cursor: pointer;
}

.image-fullscreen img {
  max-width: 90%;
  max-height: 90%;
}


.image-wrapper img:hover {
  transform: scale(1.1);
  transition: transform 0.3s;
  cursor: pointer;
}


.filter-controls {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.filter-controls button {
  color:#283A7F;
  font-size: 1.2em;
  /* font-family: 'LEMON MILK Light'; */
  font-family: 'LibreBaskerville';
  padding: 15px 10px;
  text-align: center;
  text-decoration: none;
  width: auto;
  max-width: 180px;
  margin: 0 15px; /* Ajusta el espacio entre los botones */
  border: none;
  background-color: #f7f7f7;
}

.filter-controls button:hover {
 
}

/* Agrega una animación de entrada para los botones */
.filter-controls button {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.6s ease-out forwards;
}

.active-filter {
  text-decoration: underline !important;
  color: #f49420 !important;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


@media (max-width: 1000px) {
  .image-fullscreen img{
    max-width: 100% !important;}

  .products-container {
    margin: 10px 0px 30px 10px;    
  }

}

